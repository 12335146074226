<template>
  <div class="auction-registration-list">
    <div class="container fluid">
      <h1 class="p-2 mb-2">Auction Registration List</h1>
      <!-- Filter -->
      <filter-panel
        v-model="isFilterOpened"
        @clear="clearFilter"
        @filter="getAuctionRegistration"
        card
      >
        <div class="row p-2">
          <!-- <fd-input
            v-model="filter['id']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="ID"
            name="filterAuctionRegistrationId"
            type="text"
          >
          </fd-input> -->
          <fd-input
            v-model="filter['name1[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Main Bidder Name"
            name="name"
            type="text"
          >
          </fd-input>
          <fd-select
            v-model="filter['status:id']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Status"
            :options="statusOptions"
            :optionKey="(option) => option.id"
            :optionLabel="(option) => option.name"
            :isLoading="isStatusOptionLoading"
          >
          </fd-select>
        </div>
      </filter-panel>
      <!-- Table -->
      <vue-good-table
        class="card mt-2"
        mode="remote"
        @on-row-click="
          (e) => {
            toDetailPage(e.row.id);
          }
        "
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="tableColumns"
        :rows="tableData"
        :totalRows="tablePagination.total"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        :sort-options="{
          enabled: false,
          initialSortBy: { field: 'createdAt', type: 'desc' }
        }"
        :isLoading.sync="isTableLoading"
      >
        <div slot="emptystate">
          <no-data message="There is no auction registration."></no-data>
        </div>
        <div slot="loadingContent">
          <div class="d-flex justify-content-center">
            <spinner></spinner>
          </div>
        </div>
        <div slot="table-actions">
          <fd-button
            class="toggle m-1"
            :class="{ active: isFilterOpened }"
            @click="isFilterOpened = !isFilterOpened"
          >
            <i class="fas fa-filter"></i>
          </fd-button>
        </div>
        <!-- Table Buttons -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <button class="btn edit mr-1">
              <i class="fas fa-pen"></i>
            </button>
            <button class="btn delete">
              <i class="fas fa-trash"></i>
            </button>
          </span>
          <span v-else-if="props.column.field == 'auctionId'" @click.stop>
            <fd-button
              class="py-1 flat main id-button"
              v-tooltip="`Copy ID`"
              @click="copyId($refs[props.row.id])"
            >
              <div class="d-flex">
                <div class="id-button__label">{{ props.row.id }}</div>
                <i class="fas fa-clone ml-1"></i>
              </div>
            </fd-button>
            <input v-show="false" :ref="props.row.id" :value="props.row.id" />
          </span>
          <span v-else-if="props.column.field == 'auctionStatus'">
            <auction-registration-status :status="props.row.status">
            </auction-registration-status>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { auctionRegistration as auctionAPI } from "@/api";
import { copyToClipboard } from "@/utils/string";

export default {
  components: {
    AuctionRegistrationStatus: () =>
      import("@/components/Auction/AuctionRegistrationStatus"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      statusOptions: [],
      isStatusOptionLoading: false,

      isFilterOpened: false,
      isTableLoading: false,

      tableData: [],
      tableColumns: [
        {
          label: "ID",
          field: "auctionId"
        },
        {
          label: "Status",
          field: "auctionStatus"
        },
        {
          label: "Bidder Type",
          field: "bidderType.name"
        },
        {
          label: "Agent",
          field: "property.agencyUser.name"
        },
        {
          label: "Main Bidder",
          field: "name1"
        },
        {
          label: "Contact Number",
          field: "contact1"
        },
        {
          label: "Email",
          field: "email1"
        },
        {
          label: "Property Ref.",
          field: "property.referenceCode"
        },
        {
          label: "Date",
          field: "createdAt",
          formatFn: (val) => this.$moment(val).format("DD-MM-YYYY")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      tablePagination: {
        total: 0
      },

      filter: {
        id: "",
        "status:id": "",
        "name[partial]": ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getAuctionRegistration();
      this.getStatusOptions();
    },
    toDetailPage(id) {
      this.$router.push({
        name: "AuctionRegistrationDetail",
        params: {
          id: id
        }
      });
    },
    copyId(ref) {
      try {
        copyToClipboard(ref);
        this.$notify({
          group: "alert",
          text: "Auction Registration ID is copied to clipboard"
        });
      } catch (error) {
        console.error(error);
      }
    },
    // ============================ Filter Function ============================
    filterData() {
      this.isTableLoading = true;
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      this.getAuctionRegistration().then(() => {
        this.isTableLoading = false;
      });
    },
    clearFilter() {
      this.isTableLoading = true;
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAuctionRegistration().then(() => {
        this.isTableLoading = false;
      });
    },
    // ============================ Table Functions ============================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAuctionRegistration();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAuctionRegistration();
    },
    // ============================== API Related ==============================
    async getAuctionRegistration() {
      try {
        this.isTableLoading = true;

        let data = await auctionAPI.getAuctionRegistrations({
          queries: {
            ...this.filter
          }
        });
        this.tableData = this._.cloneDeep(data.data);
        this.tablePagination = this._.cloneDeep(data.meta.pagination);
        this.isTableLoading = false;
      } catch (error) {
        this.isTableLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch auction registrations. Please try again later."
        });
        throw error;
      }
    },
    async getStatusOptions() {
      try {
        this.isStatusOptionLoading = true;
        let data = await auctionAPI.getStatus();
        this.statusOptions = this._.cloneDeep(data);
        // Change the label for OTL and ic/passport
        //
        this.isStatusOptionLoading = false;
      } catch (error) {
        this.isStatusOptionLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.auction-registration-list {
  .id-button {
    .id-button__label {
      width: 60px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
