var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auction-registration-list"},[_c('div',{staticClass:"container fluid"},[_c('h1',{staticClass:"p-2 mb-2"},[_vm._v("Auction Registration List")]),_c('filter-panel',{attrs:{"card":""},on:{"clear":_vm.clearFilter,"filter":_vm.getAuctionRegistration},model:{value:(_vm.isFilterOpened),callback:function ($$v) {_vm.isFilterOpened=$$v},expression:"isFilterOpened"}},[_c('div',{staticClass:"row p-2"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Main Bidder Name","name":"name","type":"text"},model:{value:(_vm.filter['name1[partial]']),callback:function ($$v) {_vm.$set(_vm.filter, 'name1[partial]', $$v)},expression:"filter['name1[partial]']"}}),_c('fd-select',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Status","options":_vm.statusOptions,"optionKey":function (option) { return option.id; },"optionLabel":function (option) { return option.name; },"isLoading":_vm.isStatusOptionLoading},model:{value:(_vm.filter['status:id']),callback:function ($$v) {_vm.$set(_vm.filter, 'status:id', $$v)},expression:"filter['status:id']"}})],1)]),_c('vue-good-table',{staticClass:"card mt-2",attrs:{"mode":"remote","columns":_vm.tableColumns,"rows":_vm.tableData,"totalRows":_vm.tablePagination.total,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: _vm.perPageOptions,
        dropdownAllowAll: false
      },"sort-options":{
        enabled: false,
        initialSortBy: { field: 'createdAt', type: 'desc' }
      },"isLoading":_vm.isTableLoading},on:{"on-row-click":function (e) {
          _vm.toDetailPage(e.row.id);
        },"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isTableLoading=$event},"update:is-loading":function($event){_vm.isTableLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('button',{staticClass:"btn edit mr-1"},[_c('i',{staticClass:"fas fa-pen"})]),_c('button',{staticClass:"btn delete"},[_c('i',{staticClass:"fas fa-trash"})])]):(props.column.field == 'auctionId')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('fd-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Copy ID"),expression:"`Copy ID`"}],staticClass:"py-1 flat main id-button",on:{"click":function($event){return _vm.copyId(_vm.$refs[props.row.id])}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"id-button__label"},[_vm._v(_vm._s(props.row.id))]),_c('i',{staticClass:"fas fa-clone ml-1"})])]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:props.row.id,domProps:{"value":props.row.id}})],1):(props.column.field == 'auctionStatus')?_c('span',[_c('auction-registration-status',{attrs:{"status":props.row.status}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no auction registration."}})],1),_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('fd-button',{staticClass:"toggle m-1",class:{ active: _vm.isFilterOpened },on:{"click":function($event){_vm.isFilterOpened = !_vm.isFilterOpened}}},[_c('i',{staticClass:"fas fa-filter"})])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }